import Financa from '../../../../assets/servicos/financa.png';
import Networking from '../../../../assets/servicos/networking.png';
import Social_media from '../../../../assets/servicos/social-midia.png';
import Button from '../../../../components/Button'
import { Container, ServiceTitle, ServiceContent, ServiceCard, CardText, CardCircle } from './style';

function Servicos() {
  return (<>
    <Container>
      <ServiceTitle>
        <h1>Serviços</h1>
        <p>
          Proporcionamos soluções através de serviços de
          consultoria nas áreas de<span> Gestão de Pessoas</span>,
          <span> Marketing </span>e<span> Financeiro</span>.
        </p>
      </ServiceTitle>
      <ServiceContent>
        <ServiceCard>
          <CardText>
            <h2>GESTÃO FINANCEIRA</h2>
            <ul>
              <li>Redução de Gastos</li>
              <li>Planejamento Financeiro</li>
              <li>Estruturação Financeira</li>
            </ul>
          </CardText>
          <CardCircle>
            <img src={Financa} alt='Finanças' />
          </CardCircle>
        </ServiceCard>
        <ServiceCard>
          <CardText>
            <h2>GESTÃO DE MARKETING</h2>
            <ul>
              <li>Análise Comercial</li>
              <li>Análise Concorrencial</li>
              <li>Estratégia de Comunicação</li>
              <li>Pesquisa de Mercado</li>
              <li>Pesquisa de Marketing</li>
            </ul>
          </CardText>
          <CardCircle>
            <img src={Social_media} alt='Mídias Sociais' />
          </CardCircle>
        </ServiceCard>
        <ServiceCard  >
          <CardText>
            <h2>GESTÃO DE PESSOAS</h2>
            <ul>
              <li>Avaliação de Desempenho</li>
              <li>Pesquisa de Clima Organizacional</li>
              <li>Recrutamento e Seleção</li>
            </ul>
          </CardText>
          <CardCircle>
            <img src={Networking} alt='Networking' />
          </CardCircle>
        </ServiceCard>
      </ServiceContent>
      <Button>Saiba mais</Button>
    </Container>
  </>)
}

export default Servicos;