import styled from 'styled-components';
import image from '../../../../assets/images/sobre-nos-bg.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const AboutSection = styled.section`
  font-family: 'Open sans';
  max-width: 1440px;
  padding: 2rem 6rem;
  box-shadow: 0 5px 10px -2px #b2b2b2;
  background: linear-gradient(155deg, #fff 30%, rgba(230, 230, 230, 0.88) 110%),
    url(${image});
  background-size: 100%;
  background-color: var(--gradient-color);
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    padding: 1rem;
    align-items: center;
  }
`;

export const AboutText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;

  overflow: hidden;

  span {
    font-weight: bold;
  }

  h2 {
    font-size: 1.75rem;
    padding-bottom: 4rem;
  }

  p {
    width: fit-content;
    line-height: 25px;
    font-size: 1.125rem;
  }

  .carrossel {
    width: 200%;
    display: flex;
    overflow: hidden;
  }

  .carrossel p {
    width: 50%;
    padding-inline-end: 1rem;
  }

  @media (max-width: 800px) {
    h2 {
      display: none;
    }
    .carrossel p {
      font-size: 1rem;
      padding-inline-end: 0;
    }
    .carrossel p + p {
      padding-inline-start: 1rem;
    }
  }
`;

export const CarouselButtons = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;

  button {
    border: none;
    border-radius: 40rem;
    width: 3rem;
    cursor: pointer;
    height: 5px;
    background-color: var(--button-carrossel-active);
  }

  button + button {
    background-color: var(--button-carrossel);
    margin-left: 12px;
  }
`;

export const AboutImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  h2 {
    font-size: 2rem;
    display: none;
  }

  img {
    border-radius: 4px;
    width: fit-content;
  }

  @media (max-width: 800px) {
    width: 100%;

    h2 {
      display: block;
    }

    img {
      padding: 2rem 0;
    }
  }
`;

export const Button = styled.button`
  background-color: var(--button-primary);
  color: white;
  cursor: pointer;

  border: solid 1px;
  border-radius: 5rem;

  height: 3rem;
  width: 11.75rem;
  font-weight: bold;
  font-size: 1rem;

  transition: ease-in 0.2s;

  :hover {
    background: var(--button-primary-hover);
  }

  @media (max-width: 800px) {
    align-self: center;
  }
`;
