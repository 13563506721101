import styled from 'styled-components';
import Image from '../../../../assets/images/Footer/footer-background-desktop-image.png';

const FooterContainer = styled.footer`
  font-family: 'Open sans';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  margin: 0 auto;
  /* @media (min-width: 0px) and (max-width: 600px){
    height: 3900px;
  } */
  /* z-index: -3; */
`

export const FooterContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  background: linear-gradient(155deg, #F8F8F8 35%, rgba(0,0,0,0) 60%), url(${Image});
  background-size: 100%;
  background-color: #222673;
`

export const FooterImage = styled.picture`
  left: 0;
  position: absolute;
  bottom: -5px;
  /* z-index: -1; */
  z-index: 1001;
  
  @media (max-width: 600px){
    overflow-y:hidden;
  }
  
`

export const Contact = styled.div`
  padding:0px 16px  0px 16px;
  text-align: center;
  font-size: 18px;
  .strong{
    font-weight: bold;
    font-size: 20px;
  }
  .one{
    padding-bottom:24px;
    padding-top: 54px;
  }
  @media (min-width: 0px) and (max-width: 600px){ 
    font-size: 14px;
    .strong{
      font-size: 16px;
    }
  }
`
export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content:center;
  align-items: center;
  align-content: center;
  margin-top: 24px;
  margin-bottom: 39px;
  /* z-index: 1000; */
  button{
    display: flex;
    /* align-items: center; */
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #222673;
    border: 0px;
    cursor: pointer;
    border-radius: 2px;
  }

`

export const Button = styled.button`
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(242, 195, 53, 1);
  border-radius: 50%;
  cursor: pointer;
  border-style: hidden;
  height: 40px;
  width: 40px;

  `

export const Newsletter = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  gap: 16px;
  padding-bottom:50px;

  @media (max-width: 510px){
    
  flex-direction: column;
  }
`

export default FooterContainer;