import styled from "styled-components";

export const Container = styled.div`

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 18rem;
background-color: white;

span{
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
}
p{
    font-weight: bold;
    font-size: 1.2rem;
    margin: 1.5rem 0;
}
Button{
  margin: 1.5rem 0;
}
span,p{
  padding:0 10px 0 10px;
  text-align: center;
} 

@media (max-width: 500px) {
    span {
        font-size: 0.8rem;    
    }
  }

`;