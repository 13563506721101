import styled from 'styled-components';

const ButtonStyle = styled.button`
  height: 3rem;
  width: 11.7rem;
  font-size: 14px;

  border-radius: 30px;
  background-color: var(--color-yellow);
  color: var(--color-white);

  cursor: pointer;
  border: none;

  @media (min-width: 800px) {
    font-size: 1rem;
    width: 13.75rem;
  }
`;
export default ButtonStyle;
