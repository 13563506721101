import { Container, Title, ContactText, Cards, ContactCard, ContactImg,} from './style';
import TimerIcon from '../../../../assets/images/Contacts/relogio-biologico.png';
import ProIcon from '../../../../assets/images/Contacts/profissional.png';
import SatisfactionIcon from '../../../../assets/images/Contacts/satisfacao-do-cliente.png';

function TopSection() {
  return (
    <>
      <Container>
        <Title>
          Contato
        </Title>
        <ContactText size={1.125}>
          Lorem ipsum dolor sit amet consectetur adipiscing elit
        </ContactText>
        <Cards>
          <ContactCard>
            <ContactImg>
              <img src={TimerIcon} alt="Relogio Biologico" />
            </ContactImg>
            <ContactText size={0.75}>
              Retorno em até 48h
            </ContactText>
          </ContactCard>
          <ContactCard>
            <ContactImg>
              <img src={ProIcon} alt="Profissional" />
            </ContactImg>
            <ContactText size={0.75}>
              Equipe especializada
            </ContactText>
          </ContactCard>
          <ContactCard>
            <ContactImg>
              <img src={SatisfactionIcon} alt="Satisfação do Cliente" />
            </ContactImg>
            <ContactText size={0.75}>
              Atendimento de Qualidade
            </ContactText>
          </ContactCard>
        </Cards>
      </Container>
    </>
  );
}

export default TopSection;
