import styled from 'styled-components';

export const Container = styled.section`
  .container {
    display: flex;
    flex-direction: row;
    padding: auto;
    margin-top: 2rem;
    height: 25rem;
  }

  .redes {
    width: 60%;
    height: 10px;
  }

  .redes footer div {
    background-color: transparent;
    background-image: none;
  }

  img {
    display: none;
  }

  // div das Redes Sociais
  .eALfID {
    padding: 0px 16px 0px 16px;
    text-align: center;
    font-size: 18px;
    width: 100%;
  }
  .gxpanp {
    background: transparent;
  }

  .jUTJb {
    display: none;
  }

  .embed-container {
    position: relative;
    overflow: initial;
    max-width: 100%;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: relative;
    top: 65px;
    left: 0;
    width: 400px;
    height: 250px;
  }

  @media screen and (max-width: 800px) {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }

    .embed-container {
      margin-bottom: 2rem;
    }
    .redes {
      height: 100%;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
      top: 0;
      width: 320px;
      height: 200px;
    }
  }
  @media (min-width: 1440px) {
    body {
      max-width: 1440px;
    }
  }
`;
