import Servico from "./components/Servico";
import ServiceTopSection from "./components/serviceTopSection";
import Contato from './components/Contato';
import Newsletter from './components/Newsletter';
import Carousel from "./components/parceiros-carousel/carousel";
import ClienteCarousel from "./components/clientes-carousel";

function Service() {
  return (
    <>
      <ServiceTopSection />
      <Servico />
      <Contato />
      <ClienteCarousel />
      <Carousel />
      <Newsletter />
    </>
  );
}

export default Service;
