import React from 'react';
import { Container } from './style.js'
import Footer from '../../../home/components/Footer/index';

function ComponenteMapa() {
  return (
    <Container>
      <div className='container'>
        <div className='redes'><Footer tittle="Onde nos encontrar" textFirst="Av. Conde da Boa Vista, 921 - Boa Vista," textSecond="Recife - PE, 50060-002" textThree="contato@projetosconsultoria.com" textFouth="Tel:(81) 2122-3503" /></div>
        <div class="embed-container">
          <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15801.675294684566!2d-34.8891069!3d-8.0586899!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42abf4657cec0445!2sProjetos%20Jr.%20Consultoria!5e0!3m2!1spt-BR!2sbr!4v1650934490083!5m2!1spt-BR!2sbr" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </Container>
  )
}

export default ComponenteMapa;