import styled from 'styled-components';

export const Container = styled.section`
  margin: 2rem 0;

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none;
  }

  .alice-carousel__wrapper {
    text-align: center;
  }
  /* alinha itens do carousel para o centro do body */

  h1 {
    font-size: 24px;
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
  }

  .alice-carousel {
    max-width: 90%;
    max-height: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  /* delimitação para o tamanho da div, evita desalinhamento no título */

  img {
    width: 100px;
    height: 100%;
    
  }

  .alice-carousel__dots {
    margin-top: 50px;
  }

  @media screen and (max-width: 999px) {
    .alice-carousel {
      width: 800px;
    }
  }

  @media screen and (max-width: 450px) {
    .alice-carousel {
      width: 400px;
    }
  }

  @media (min-width:1440px) { 
    body {
        max-width:1440px
    }
}
  `;
