import { Container, Image } from './style';
import Button from '../../../../components/Button'
import DesktopImage from '../../../../assets/images/newletter-image.png'

function Newsletter() {
  return (
    <Container>
      <Image src={DesktopImage} alt="Formas geométricas" />
      <h1>Não perca nenhuma novidade!</h1>
      <p>Assine nossa newsletter:</p>
      <input type="email" name="email" id="email" placeholder="E-mail" />
      <Button>Assinar</Button>
    </Container>
  );
}

export default Newsletter;