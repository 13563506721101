import { Container, Carousel } from './style';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { arrayClientes, Cliente } from './clientes';
import 'swiper/swiper-bundle.css';

export default function ClientesCarousel() {
  return (
    <Container>
      <h1>O que dizem nossos clientes...</h1>
      <Carousel>
        <Swiper
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          pagination={true}
          modules={[Autoplay, Navigation, Pagination]}
          navigation={true}
        >
          {arrayClientes.map((objCliente, index) => {
            return (
              <SwiperSlide id={index}>
                {<Cliente objCliente={objCliente} />}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Carousel>
    </Container>
  );
}