import { useRef } from 'react';
import {
  AboutSection,
  AboutImage,
  Button,
  Container,
  CarouselButtons,
  AboutText,
} from './style.js';

function AboutUs({ img }) {
  const text = useRef(null);
  const buttonLeft = useRef(null);
  const buttonRight = useRef(null);

  const handleTextToRight = (e) => {
    e.preventDefault();

    text.current.style.transition = '.8s';
    text.current.style.marginLeft = '-50%';

    buttonRight.current.style.backgroundColor = 'var(--button-carrossel-active)';
    buttonLeft.current.style.backgroundColor = 'var(--button-carrossel)';
  };

  const handleTextToLeft = (e) => {
    e.preventDefault();
    text.current.style.marginLeft = '0%';

    buttonLeft.current.style.backgroundColor = 'var(--button-carrossel-active)';
    buttonRight.current.style.backgroundColor = 'var(--button-carrossel)';
  };

  return (
    <>
      <Container>
        <AboutSection>
          <AboutText>
            <h2>Sobre nós</h2>
            <div className="carrossel">
              <p ref={text}>
                A Projetos Jr. Consultoria, é uma empresa Júnior
                multidisciplinar formada por alunos graduandos dos cursos de
                Administração, Ciências Contábeis, Logística, Gestão Financeira,
                Gestão Comercial, Recursos Humanos e Psicologia, da Faculdade{' '}
                <span>FAFIRE</span>.
              </p>
              <p>
                Desenvolvemos serviços de assessoria e consultoria empresarial
                especializada que se adequem à realidade econômica de{' '}
                <span>micro e pequenas empresas</span> situadas na região
                metropolitana do <span>Recife</span>.
              </p>
            </div>
            <CarouselButtons>
              <button ref={buttonLeft} onClick={handleTextToLeft} />
              <button ref={buttonRight} onClick={handleTextToRight} />
            </CarouselButtons>

            <Button>Saiba mais</Button>
          </AboutText>

          <AboutImage>
            <h2>Sobre nós</h2>
            <img src={img} alt="Projetos JR." />
          </AboutImage>
        </AboutSection>
      </Container>
    </>
  );
}

export default AboutUs;
