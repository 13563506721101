import TopSection from './components/TopSection';
import AboutUs from './components/AboutUs';
import Servicos from './components/Servicos';
import Footer from './components/Footer';

import SectionImage from '../../assets/images/sobre-nos.png';

function Home() {
  return (
    <>
      <TopSection />
      <AboutUs img={SectionImage} />
      <Servicos />
      <Footer tittle="Precisa de ajuda?" textFirst="Estamos aqui para ajudá-lo." textSecond="Entre em contato por telefone, email ou redes sociais."/>
    </>
  );
}

export default Home;