import Button from '../../../../components/Button';
import { CloseButton, Container, Content, Title, Text } from './style'
import { BsArrowRightCircle } from "react-icons/bs";

import Input from '../../../../components/Input';
function Popup(props) {
  return (props.trigger) ? (
    <>
      <Container>
        <Content>
          <Title>Não perca nenhuma novidade!</Title>
          <Text>Assine nossa newsletter:</Text>
          <CloseButton onClick={() => props.setTrigger(false)}>
            <BsArrowRightCircle style={{ width: '24px', height: '24px', color: '#989898' }}></BsArrowRightCircle>
          </CloseButton>
          <input placeholder={'E-mail:'} type={'email'}></input>
          <Button>{'Assinar'}</Button>
        </Content>
      </Container>
    </>
  ) : ''
}

export default Popup;