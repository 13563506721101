import Containerservice, { Image } from './style';
import ServiceImage from '../../../../assets/images/service-top-section.png';
import ServiceImageMobile from '../../../../assets/images/service-top-section-mobile.png';

function ServiceTopSection() {
  return (
    <Containerservice>
      <Image src={ServiceImage} alt="Formas geométricas" />
      <div>
        <h1>SERVIÇOS</h1>
        <p>Proporcionamos soluções através de serviços de consultoria nas áreas de <b>Gestão de Pessoas</b>, <b>Marketing</b> e <b>Financeiro</b>.</p>
      </div>
    </Containerservice>
  );
}

export default ServiceTopSection;