import React, { Component } from "react";
import { Container } from './style'
import Button from '../../../../components/Button';

function Contato() {
  return (
    <Container>

      <p>Se interessou? Entre em contato!</p>

      <span>Estamos aqui para ajuda-lo.</span>
      <span>Entre em contato por telefone, email ou redes sociais</span>

      <Button>Contato</Button>


    </Container>
  );
}

export default Contato;
