import GlobalStyle from './assets/styles/GlobalStyle';

import BlueRectangle from './components/BlueRectangle'
import Navbar from './components/Navbar';
import Routes from './router';

function App() {
  return (
    <>
      <GlobalStyle />
      <Routes />
      <BlueRectangle />
    </>
  );
}

export default App;
