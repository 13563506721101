import { Cabecalho, Content, Descricao, DivCliente, Logo } from './style';
import logoCirculoTriangulo from '../../../../assets/images/circulo_triangulo.png';

export function Cliente({ objCliente }) {
  return (
    <Content>
      <Cabecalho>
        <Logo>
          <img src={objCliente.img} alt={objCliente.altImg} />
        </Logo>
        <DivCliente>
          <h3>{objCliente.nomeCliente}</h3>
          <p>{objCliente.cargoCliente}</p>
        </DivCliente>
      </Cabecalho>
      <Descricao>{objCliente.descricao}</Descricao>
    </Content>
  );
}

export const arrayClientes = [
  // Pra adicionar um novo cliente basta colocar um novo objeto nesse array.
  {
    img: logoCirculoTriangulo,
    altImg: 'Simbolo com circulo e triângulo',
    nomeCliente: 'Luiz Felipe',
    cargoCliente: 'CEO da Datacert',
    descricao:
      'Contar com a Projetos Jr. no Recrutamento e Seleção  do nosso time foi uma escolha acertada. O Comprometimento e a transparência da equipe de consultores deixou a seleção com a nossa cara e fez com que fosse conduzido com excelência. Trabalhamos em equipe, cada um desempenhou o seu papel da melhor forma, e chegamos ao final dentro do cronograma planejado. Passados quase seis meses de seleção, temos a certeza da melhor escolha, a equipe selecionada com apoio da Projetos Jr. vem desenvolvendo bem o seu papel, engajada e alinhada com os objetivos da empresa',
  },
  {
    img: logoCirculoTriangulo,
    altImg: 'Simbolo com circulo e triângulo',
    nomeCliente: 'Luiz Felipe',
    cargoCliente: 'CEO da Datacert',
    descricao:
      'Contar com a Projetos Jr. no Recrutamento e Seleção  do nosso time foi uma escolha acertada. O Comprometimento e a transparência da equipe de consultores deixou a seleção com a nossa cara e fez com que fosse conduzido com excelência. Trabalhamos em equipe, cada um desempenhou o seu papel da melhor forma, e chegamos ao final dentro do cronograma planejado. Passados quase seis meses de seleção, temos a certeza da melhor escolha, a equipe selecionada com apoio da Projetos Jr. vem desenvolvendo bem o seu papel, engajada e alinhada com os objetivos da empresa',
  },
  {
    img: logoCirculoTriangulo,
    altImg: 'Simbolo com circulo e triângulo',
    nomeCliente: 'Luiz Felipe',
    cargoCliente: 'CEO da Datacert',
    descricao:
      'Contar com a Projetos Jr. no Recrutamento e Seleção  do nosso time foi uma escolha acertada. O Comprometimento e a transparência da equipe de consultores deixou a seleção com a nossa cara e fez com que fosse conduzido com excelência. Trabalhamos em equipe, cada um desempenhou o seu papel da melhor forma, e chegamos ao final dentro do cronograma planejado. Passados quase seis meses de seleção, temos a certeza da melhor escolha, a equipe selecionada com apoio da Projetos Jr. vem desenvolvendo bem o seu papel, engajada e alinhada com os objetivos da empresa',
  },
];