import { useState } from "react";
import FooterContainer, { Contact, Icons, FooterImage, Button, Newsletter, FooterContent } from "./style";
import { BsArrowRight } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

import Popup from "../Popup";

import DesktopImage from '../../../../assets/images/Footer/footer-desktop-image.png';
import MobileImage from '../../../../assets/images/Footer/footer-mobile-image.png';

function Footer(props) {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <FooterContainer>
      <FooterContent>
        <FooterImage>
          <source media="(max-width:920px)" srcSet={MobileImage} />
          <img src={DesktopImage} alt="Desktop" />
        </FooterImage>

        <Contact>
          <p className="strong one">{props.tittle}</p>
          <p>{props.textFirst}</p>
          <p>{props.textSecond}</p>
          <p>{props.textThree}</p>
          <p>{props.textFouth}</p>

          <Icons>
            <a href="https://www.facebook.com/projetosjrconsultoria/" target="_blank" rel="noreferrer">
              <button><FaFacebookF size={30} color='#fff' /></button>
            </a>

            <a href="https://www.instagram.com/projetosjrconsultoria/?hl=pt-br" target="_blank" rel="noreferrer">
              <button><GrInstagram size={40} color='#fff' /></button>
            </a>

            <a href="https://www.linkedin.com/company/projetos-consultoria/?originalSubdomain=br" target="_blank" rel="noreferrer">
              <button><FaLinkedinIn size={34} color='#fff' /></button>
            </a>
          </Icons>

          <Newsletter>
            <p className="strong two">Não perca nenhuma novidade!</p>
            <Button onClick={() => setButtonPopup(true)}>
              <BsArrowRight size={23} color='#fff' />
            </Button>
          </Newsletter>
          <Popup trigger={buttonPopup} setTrigger={setButtonPopup}></Popup>
        </Contact>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;