import "./style.css";
import { useRef, useState } from "react";
import LogoPJC from '../../assets/images/logo-pjc1.jpg';

import { Link } from 'react-router-dom'
import Popup from "../../pages/home/components/Popup";

const Navbar = () => {
  const [buttonPopup, setButtonPopup] = useState(false);

  const list = useRef(null);
  const hamb = useRef(null);

  const onClick = () => {
    const span = list.current; // corresponding DOM node
    const div = hamb.current;

    var css = (span.className === "menu active") ? "none" : "active";
    var cssi = (div.className === "active") ? "" : "active";

    span.className = "menu " + css;
    div.className = cssi;
  };

  return (
    <nav>
      <div className="navbar">

        <div className="logo">
          <Link to="/"><img src={LogoPJC} alt="Logo" /></Link>
        </div>

        <ul className="menu" ref={list}>
          <li className="hidden"><Link to="/">home</Link></li>
          <li><Link to="/service">serviço</Link></li>
          <li><Link to="/contacts">contato</Link></li>
          <li className="hidden"><span onClick={() => setButtonPopup(true)}>newsletter</span></li>
        </ul>

        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}></Popup>

        <div onClick={onClick} id="hamburguer" ref={hamb} >
          <span></span>
          <span></span>
          <span></span>
        </div>

      </div>
    </nav>
  );
}

export default Navbar;
