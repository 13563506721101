import styled from 'styled-components';
import Pattern from '../../../../assets/images/pattern.png';
import ArrowLeft from '../../../../assets/images/arrow-left.svg';
import ArrowRight from '../../../../assets/images/arrow-right.svg';

export const Container = styled.section`
  width: 100%;
  background: black;
  background-image: linear-gradient(
      139.14deg,
      #f8f8f8 61.22%,
      rgba(255, 254, 254, 0.88) 88.93%
    ),
    url('${Pattern}');
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 48px 0px;

  h1 {
    font-size: 24px;
  }

  @media (max-width: 855px) {
    h1 {
      font-size: 20px;
    }
  }

  @media (max-width: 280px) {
    h1 {
      font-size: 19px;
    }
  }
`;

export const Carousel = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;

  .swiper {
    width: 1024px;
  }

  .swiper-slide {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    border-radius: 10px;
    width: 55px;
    height: 6px;
  }

  .swiper-pagination-bullet-active {
    background: #858585;
  }

  .swiper-button-prev {
    background-image: url(${ArrowLeft});
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next {
    background-image: url(${ArrowRight});
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: center;
  }

  .swiper-button-next::after {
    display: none;
  }

  @media (max-width: 855px) {
    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }
  }

  @media (min-width: 855px) {
    .swiper-pagination {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  max-width: 720px;
`;

export const Cabecalho = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 70%;
  text-shadow: 5px;
  box-shadow: 0px 4px 10px rgba(34, 38, 115, 0.25);

  img {
    width: 37px;
    height: 33px;
  }
`;

export const DivCliente = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  margin-left: 24px;

  h3 {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const Descricao = styled.p`
  margin-top: 24px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 50px;

  @media (max-width: 855px) {
    max-width: 490px;
  }

  @media (max-width: 525px) {
    max-width: 390px;
  }

  @media (max-width: 425px) {
    max-width: 290px;
  }

  @media (max-width: 320px) {
    max-width: 250px;
  }
`;