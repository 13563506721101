import ComponenteMapa from "./components/mapsContacts";
import TopSection from "./components/TopSection";

function Contacts() {
  return (
    <>
      <TopSection/>
      <ComponenteMapa/>
    </>
  );
}

export default Contacts;