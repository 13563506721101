import Container, { WrapperContent, Image } from './style';
import Button from '../../../../components/Button';

import MobileImage from '../../../../assets/images/top-section-mobile.png';
import DesktopImage from '../../../../assets/images/top-section-desktop.png';

function TopSection() {
  return (
    <Container>
      <WrapperContent>
        <div>
          <h1>
            PROPORCIONAR <span>SOLUÇÕES</span> É O <span>NOSSO SERVIÇO</span>
          </h1>
          <h2>
            Descubra como uma empresa de jovens pode transformar sua empresa.
          </h2>
        </div>
        <picture>
          <source media="(max-width:780px)" srcset={MobileImage}/>
          <Image src={DesktopImage} alt="Formas geométricas"/>
        </picture>
        <Button>Solicite uma reunião</Button>
      </WrapperContent>
    </Container>
  );
}

export default TopSection;
