import React, { useState, useEffect } from "react";
import { CardLeft, CardRight, Container, Content, Space, Text, Top, TextMobile } from './style'
import { gFinanceira, gPessoas, gProcessos, gMarketing } from './store'

function Servico() {
  const [mng, setMng] = useState(gProcessos.data);
  const [mobTitle, setMobTitle] = useState(mng[0].title);
  const [mobText, setMobText] = useState(mng[0].text);

  useEffect(() => {
    const newTxt = mobText.replace(/\n/g, '<br>');
    setMobText(newTxt)
  }, [mobText])


  const handleMng = (e) => {
    if (e === 'g-fi') {
      setMng(gFinanceira.data)
      setMobTitle(gFinanceira.data[0].title)
      setMobText(gFinanceira.data[0].text)
    }
    if (e === 'g-pe') {
      setMng(gPessoas.data)
      setMobTitle(gPessoas.data[0].title)
      setMobText(gPessoas.data[0].text)
    }
    if (e === 'g-pr') {
      setMng(gProcessos.data)
      setMobTitle(gProcessos.data[0].title)
      setMobText(gProcessos.data[0].text)
    }
    if (e === 'g-ma') {
      setMng(gMarketing.data)
      setMobTitle(gMarketing.data[0].title)
      setMobText(gMarketing.data[0].text)
    }
  }

  const handleMobileMng = (e) => {
    setMobText(mng[e].text)
    setMobTitle(mng[e].title)
  }

  return (
    <Container>
      <Top>
        <select onChange={e => handleMng(e.target.value)}>
          <option value="g-pr">Gestão de Processos</option>
          <option value="g-fi">Gestão Financeira</option>
          <option value="g-pe">Gestão de Pessoas</option>
          <option value="g-ma">Gestão de Markenting</option>
        </select>

        <select onChange={e => handleMobileMng(e.target.value)}>
          {mng.map((item, index) => {
            return <option value={index} key={index}>{item.title}</option>
          })}
        </select>

        <span onClick={e => handleMng('g-pr')}> Gestão de Processos</span>
        <span onClick={e => handleMng('g-fi')}> Gestão Financeira</span>
        <span onClick={e => handleMng('g-pe')}> Gestão de Pessoas</span>
        <span onClick={e => handleMng('g-ma')}> Gestão de Markenting</span>
      </Top>

      <TextMobile>
        <h2>{mobTitle}</h2>
        <p>{mobText}</p>
        {mng[0].textS && <>
          <br />
          <p>{mng[0].textS}</p>
          <br />
          <p>{mng[0].textT}</p>
        </>}
      </TextMobile>

      <Content>
        {mng.map((item, index) => {
          return index % 2 === 0 ?
            (<CardRight key={index}>
              <Text>
                <h2>{item.title}</h2>
                <p>
                  {item.text}
                </p>{mng[0].textS && <>
                  <br />
                  <p>{mng[0].textS}</p>
                  <br />
                  <p>{mng[0].textT}</p>
                </>}
              </Text>
              <Space />
            </CardRight>) :

            (<CardLeft key={index}>
              <Space />
              <Text>
                <h2>{item.title}</h2>
                <p>
                  {item.text}
                </p>
              </Text>
            </CardLeft>)
        })}
      </Content>
    </Container >
  );
}

export default Servico;
