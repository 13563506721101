import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Container } from './style.js'
import parc01 from './../../../../assets/images/parceiroOpine.png'
import parc02 from './../../../../assets/images/parceiroMotim.png'
import parc03 from './../../../../assets/images/parceiroFafire.jpg'
import parc04 from './../../../../assets/images/parceiroSupra.png'

const handleDragStart = (e) => e.preventDefault();
const responsive = {
  0: { items: 1 },
  300: { items: 2 },
  700: { items: 3 },
  1000: { items: 4 },
};

const items = [
  <img src={parc01} alt= "parceiro 01" onDragStart={handleDragStart} role="presentation" />,
  <img src={parc02} alt= "parceiro 02" onDragStart={handleDragStart} role="presentation" />,
  <img src={parc03} alt= "parceiro 03" onDragStart={handleDragStart} role="presentation" />,
  <img src={parc04} alt= "parceiro 04" onDragStart={handleDragStart} role="presentation" />,
]; 
 
function Gallery() { 
  return (
    <Container>
      <h1>Parceiros</h1>
      <AliceCarousel mouseTracking items={items}
      autoPlay
      autoPlayInterval={3000}
      infinite
      items={items}
      responsive={responsive}
      controlsStrategy='alternate' />
    </Container>  
    );
}

export default Gallery;