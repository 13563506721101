import styled from 'styled-components';
//padding-left: 45rem;
const Containerservice = styled.section`
  margin: 0 auto;
  position: relative;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;

  b {
    font-weight: bold;
  }

  div {
    padding-top: 10rem;
    padding-inline: 5rem;
  }

  p {
    padding-top: 3rem;
    text-align: center;
    font-size: 18px;
    max-width: 35rem;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    color: black;
    font-size: 2rem;
  }

  @media (max-width: 900px) {
    div {
      padding-inline: 1rem;
    }
  }

  @media (max-width: 700px) {
    div {
      padding-top: 7rem;
      padding-inline: 1rem;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }

    div {
      padding-inline: 1.5rem;
    }
  }
`;

export const Image = styled.img`
  position: relative;
  z-index: -1;
  margin-top: -7rem;
  width: 30rem;

  @media (max-width: 1100px) {
    margin-top: -3rem;
    width: 22rem;
  }

  @media (max-width: 700px) {
    margin-left: -7rem;
    width: 20rem;
  }

  @media (max-width: 500px) {
    position: absolute;
    top: -4rem;
    left: -10rem;
    width: 20rem;
  }
`;

export default Containerservice;
