import styled from 'styled-components';
import Pattern from '../../../../assets/images/pattern.png';

export const Container = styled.section`
  position: relative;
  padding: 6.25rem 0 2rem 6rem;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-x: hidden;
  overflow-y: hidden; 
  background: linear-gradient(333deg, #fff 50%, rgba(230, 230, 230, 0.9) 120%), url(${Pattern});
  background-color: var(--gradient-color);

  h1 {
    font-weight: bold;
    font-size: 1.125rem;
  }

  input {
    width: 20rem;
    border: 0.6px solid #222673;
    border-radius: 4px;
    padding: .5rem 0 .5rem 1rem;
  }
  
  @media (max-width: 700px) {
    padding-left: 0;
    align-items: center;
  }

  @media (max-width: 400px) {
    input {
    width: 17rem;
  }
  }
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: -2rem;

  @media (max-width: 900px) {
    width: 22rem;
  }

  @media (max-width: 780px) {
    width: 18rem;
  }

  @media (max-width: 700px) {
    right: -15rem;
  }

  @media (max-width: 500px) {
    bottom: -2rem;
  }
`;