import styled from 'styled-components';
import Divisor from '../../../../assets/servicos/divisor.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
`;
export const ServiceTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 35.625rem;
  text-align: center;
  p{
    line-height: 1.3;
    font-size: 1.125rem;
  }
  span {
    font-weight: bold;
  }
  h1 {
    font-size: 2rem;
    padding-bottom: 1rem;
  }
  @media (max-width: 1000px) {
    width: 18.125rem;
  }
`;

export const ServiceContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  margin: 2.5rem 0;
  @media (min-width: 1000px) {
    background-image: url(${Divisor});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const ServiceCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    padding-bottom: 2.25rem;
  }
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
  @media (max-width: 1000px) {
    &:nth-child(n) {
      flex-direction: column-reverse;
      padding-bottom: 2.5rem;
    }
  }
`;

export const CardText = styled.div`
  text-align: center;
  width: 18.75rem;
  h2{
    padding: 1rem;
    font-weight: bold;
    font-size: 1.25rem;
  }
  ul {
    text-align: center;
  }
  li {
    padding: 2px;
    font-size: 1rem;
  }
  @media (max-width: 1000px) {
    padding-top: 1rem;
  }
`;

export const CardCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background: var(--color-snow);
  box-shadow: 0px 6px 20px rgba(34, 38, 115, 0.25);
  @media (max-width: 1000px) {
    padding-bottom: 0px;
  }
`;