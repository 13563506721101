import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width 100%;
    height: 100%;
    background : rgba(60, 60, 60, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
`;

export const Content = styled.div`
    position: relative;
    background: white;
    border-radius: 8px;
    width: 380px;
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    Button {
        margin-top: 40px;
    }
    input {
        height: 28px;
        width: 90%;
        padding-left: 10px;
    }
    @media (max-width : 720px) {
        width: 288px
    }
`;

export const Title = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin-top: 64px;
`;

export const Text = styled.p`
    font-size: 14px; 
    margin: 24px 0;
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;