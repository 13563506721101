export const gFinanceira = {
  data: [{
    title: 'Planejamento e Viabilidade Financeira',
    text: 'É um processo de organização dos recursos financeiros da empresa. O uso planejado e bem gerenciado dos recursos financeiros garante que a empresa tenha sempre dinheiro disponível para possíveis emergências e também poder fazer investimentos com mais segurança',
  }, {
    title: 'Redução de Custos',
    text: 'É o registro contábil dos custos das operações produtivas das empresas, através de contas de custeio. Através dessa análise, o cliente terá uma maior exatidão dos gastos, sendo capaz de diminuir o custo de produção e aumentar sua margem de lucro'
  }]
}

export const gPessoas = {
  data: [{
    title: 'Análise de clima organizacional',
    text: 'A análise de clima organizacional permite investigar e entender a percepção dos funcionários de uma empresa em relação ao seu ambiente de trabalho. Conhecer essa percepção é fundamental, uma vez que a forma como as pessoas se sentem no ambiente de trabalho reflete em seu desempenho.'
  }, {
    title: 'Análise e descrição de cargos',
    text: 'O objetivo da descrição é tornar claras as atividades exercidas e o que se espera do profissional que ocupa determinada função. Nela, deve constar ainda a formação necessária e algum conhecimento específico que seja essencial ao trabalho no dia a dia. Pode ser utilizada para fins do Recrutamento e Seleção'
  }, {
    title: 'Recrutamento e seleção',
    text: 'O objetivo é recrutar profissionais estrategicamente, de acordo com o perfil determinado pelo cliente.'
  }]
}

export const gMarketing = {
  data: [{
    title: 'Cliente Oculto',
    text: 'O cliente oculto vai agir como um cliente comum, a diferença é que ele vai analisar todos os detalhes do processo de vendas. Além disso, ele analisa todos os processos da empresa como: marketing, vendas, sucesso do cliente.'
  }, {
    title: 'Pesquisa de Imagem e Satisfação',
    text: 'Coleta de dados de clientes antigos, possíveis clientes ou qualquer outro indivíduo que possa dar informações úteis para saber como está a imagem de uma empresa no mercado. A partir desses dados, a empresa pode tomar atitudes para melhorar a satisfação do cliente e os seus serviços, além de conhecer o seu público para conseguir atingir ainda mais clientes.'
  }, {
    title: 'Pesquisa de Mercado',
    text: 'É o entendimento de tudo que envolve o mercado em que a empresa se encontra, como clientes, concorrentes, fornecedores, e o quanto a empresa conhece em dados e informações tais aspectos.'
  }]
}

export const gProcessos = {
  data: [{
    title: 'Gestão de processos:',
    text: 'É um serviço dividido em mapeamento e otimização de processos.',
    textS: '1. Mapeamento de processos: basicamente consiste em mapear cada processo (etapas), o fluxo de trabalho da empresa.',
    textT: '2. Otimização de processos: a partir do mapeamento, é possível otimizar esses processos com insights e métodos individuais para cada empresa, para que possa melhorar os problemas apresentados no mapeamento.'
  },]
}