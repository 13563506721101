import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem;
  text-align: center;
  @media (max-width: 540px) {
    margin: 3rem 0;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 1rem;
`;

export const ContactText = styled.p`
  font-size: ${(props) => props.size}rem;
  padding: 0 32px;
`;

export const Cards = styled.div`
  display: flex;
  width: 75%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 2rem;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  img {
    height: 40px;
    width: 40px;
  }
`;

export const ContactImg = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-snow);
  box-shadow: 0px 4px 10px rgba(34, 38, 115, 0.25);
  margin: 1rem;
  img {
    height: 40px;
    width: 40px;
  }
`;
