import styled from 'styled-components';
import Pattern from '../../../../assets/images/pattern.png';
import LineImg from '../../../../assets/images/Line.png';

export const Container = styled.section`
  width: 100%;
  background-color: #222673;
  padding-bottom: 2rem;
  background: gray;
  background-image: linear-gradient(
      180deg,
      #222673 60%,
      rgba(34, 38, 115, 0.9) 122.44%
    ),
    url('${Pattern}');

    h2 {
    margin-bottom: 0.5rem;
    color: #ffffff;
    font-weight: normal;
  }

  span {
    margin: 0 2rem 0 2rem;
    cursor: pointer;
    font-size: 1.125rem;
  }

  span:hover {
    text-shadow: 0px 0px 1px white;
  }

  span:after {
    padding-top: 4px;
    content: '';
    display: block;
    border-bottom: 2px solid white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 100%;
  }

  span:hover:after {
    transform: scaleX(1);
  }

  @media (max-width: 700px) {
    span {
      font-size: 15px;
    }
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: normal;
  padding-top: 20px;

  select {
    display: none;
  }

  span {
    text-align: center;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;

    span {
      display: none;
    }

    select {
      width: 290px;
      font-size: 1rem;
      background-color: transparent;
      padding: .4rem .4rem;
      color: #fff;
      text-align: center;
      display: flex;
      margin-bottom: 0.8rem;
      border: none;
      text-indent: 5px;
      font-weight: bold;
    }

    select + select {
      font-weight: normal;
      margin-top: 1rem;
      border-width:1px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.72);
      border-radius: 5px;
    }

    option {
      background-color: #222673;
      padding: 2rem 0;
    }
  }
`;

export const Content = styled.div`

  max-width: 1440px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-image: url(${LineImg});
  background-repeat: repeat-y;
  background-position: center;

 

  @media (max-width: 600px) {
    margin: 2rem 1rem 0 1rem;
    background: none;
    justify-content: center;
    display: none;
  }
`;

export const CardRight = styled.div`
  display: flex;
  text-align: right;
  color: #ffffff;
  width: 100%;
  margin-top: 2rem;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const CardLeft = styled.div`
  display: flex;
  text-align: left;
  color: #ffffff;
  width: 100%;
  margin-top: 2rem;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const Space = styled.div`
  width: 50%;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Text = styled.div`
  width: 50%;

  h2,
  p {
    padding: 0 2rem 0;
  }

  @media (max-width: 700px) {
    h2,
    p {
      padding: 0 1rem 0;
    }
  }

  @media (max-width: 600px) {
    display: none;
    width: 85%;
    justify-content: center;
    text-align: left;

    h2 {
      font-size: 20px;
      text-align: center;
    }

    p {
      font-size: 18px;
      padding: 0;
      color: #fffefee0;
    }
  }
`;

export const TextMobile = styled.div`
  display: none;
  margin: 2rem auto;

  @media (max-width: 600px) {
    
    display: block;
    width: 85%;
    justify-content: center;
    text-align: left;

    h2 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 1rem;
    }

    p {
      font-size: 18px;
      padding: 0;
      color: #fffefee0;
    }
  }
`;
