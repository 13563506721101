import styled from 'styled-components';

const Container = styled.section`
  padding: 6.25rem 0 6rem 6rem;
  max-width: 1440px;
  margin: 0 auto;

  h1 {
    font-size: 2rem;
    padding-inline-end: 40%;
    span {
      font-weight: bold;
    }
  }

  h2 {
    padding: 2rem 0;
    font-size: 1.25rem;
    padding-inline-end: 45%;
  }

  @media (max-width: 780px) {
    h1, h2 {
      padding-inline-end: 30%;
    }
  }

  @media (max-width: 680px) {
    padding-bottom: 16rem;

    h1, h2 {
      padding-inline-end: 5rem;
    }
  }

  @media (max-width: 500px) {
    padding-left: 2rem;

    h2 {
      padding-inline-end: 1rem;
    }

    h1 {
      font-size: 1.5rem;
      padding-inline-end: .8rem;
    }
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;

`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  top: -11rem;
  z-index: -1;

  @media (max-width: 850px) {
    width: 20rem;
    top: -9rem;
  }

  @media (max-width: 780px) {
    width: 10rem;
    top: 10rem;
  }
`;

export default Container;
