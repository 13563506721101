import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'

import Home from "./pages/home";
import Service from "./pages/service"
import Contacts from "./pages/Contacts"
import Navbar from './components/Navbar';

function Routes() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/service" component={Service} />
          <Route path="/contacts" component={Contacts} />
        </Switch>
      </Router>
    </>
  );
}

export default Routes;
